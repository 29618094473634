import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
    AlertMessageService,
    AplicacaoService,
    CookieService,
    IMenu,
    LocalStorageService,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { menuRoles } from './core/constants/roles';
import { ControladoriaService } from './core/services/relatorios/controladoria.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    MODE = environment.MODE;
    URL_LOGIN = environment.URL_LOGIN;
    URL_LOGOUT = environment.URL_LOGOUT;
    menuItens: IMenu[] = [
        {
            label: 'Lista Telefônica',
            roles: menuRoles.itemMenu.listaTelefonica.roles,
            itens: [
                {
                    label: 'Contatos',
                    icone: 'perm_contact_calendar',
                    link: '/lista-telefonica/contatos',
                    roles: menuRoles.itemMenu.listaTelefonica.contato
                },
                {
                    label: 'Emissoras',
                    icone: 'badge',
                    link: '/lista-telefonica/emissoras',
                    roles: menuRoles.itemMenu.listaTelefonica.emissoras
                }
            ]
        },
        {
            label: 'Relatórios',
            roles: menuRoles.itemMenu.relatorios.roles,
            itens: [
                {
                    label: 'Controladoria',
                    icone: 'list_alt',
                    action: () => this._getRelatorioControladoria(),
                    roles: menuRoles.itemMenu.relatorios.controladoria
                }
            ]
        },
        {
            label: 'Parâmetros',
            roles: menuRoles.itemMenu.parametros.roles,
            itens: [
                {
                    label: 'Tipo Contato',
                    icone: 'contacts',
                    link: '/parametros/tipo-contato',
                    roles: menuRoles.itemMenu.parametros.tipoContato
                },
                {
                    label: 'Tipo Visualização',
                    icone: 'visibility',
                    link: '/parametros/tipo-visualizacao',
                    roles: menuRoles.itemMenu.parametros.tipoVisualizacao
                },
                {
                    label: 'Salas',
                    icone: 'chair',
                    link: '/parametros/salas',
                    roles: menuRoles.itemMenu.parametros.salas
                }
            ]
        }
    ];

    constructor(
        private _aplicacaoService: AplicacaoService,
        @Inject(PERMISSOES_SERVICE_TOKEN) private permissoesService: PermissoesService,
        private _controladoriaService: ControladoriaService,
        private _datePipe: DatePipe,
        private _alerts: AlertMessageService,
        private __localStorage: LocalStorageService,
        private _cookie: CookieService
    ) {
        permissoesService.initPermissoes();
    }

    ngOnInit() {}

    async _getRelatorioControladoria() {
        this._aplicacaoService.setLoadingPage(true);
        try {
            const response = await this._controladoriaService.getRelatorioControladoria();
            const blobUrl = window.URL.createObjectURL(response);
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = `Relatório_Ramais_Controladoria_${this._getCurrentDate()}.xls`;
            a.click();
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error(error);
            this._alerts.mostrarMensagemErroHttp();
        } finally {
            this._aplicacaoService.setLoadingPage(false);
        }
    }

    async logout(event: boolean) {
        if (event) {
            try {
                //TODO: LOGOUT POR API FICARÁ PARA OUTRA FASE
                this.clearCookie();
                this.__localStorage.clear();
                window.open(this.URL_LOGOUT, '_self');
            } catch (error) {
                console.error(error);
            }
        }
    }

    private clearCookie() {
        let domain = '';
        if (this.MODE == 'dev' || this.MODE == 'prod') {
            domain = 'tvsbt.com.br';
        }
        this._cookie.delete('access_token', '/', domain);
        this._cookie.delete('refreshToken', '/', domain);
    }

    private _getCurrentDate() {
        const currentDate = new Date();
        const formattedDate = this._datePipe.transform(
            currentDate,
            'EEEE, MMMM d, y, HH:mm:ss',
            undefined,
            'pt-BR'
        );
        return formattedDate?.replace(/\s/g, '_').replace(/,/g, '');
    }
}

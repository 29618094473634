import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { menuRoles } from './core/constants/roles';
import { permissaoRotaGuard } from './core/guards/permissao-rota.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'lista-telefonica/contatos'
    },
    {
        path: 'lista-telefonica/emissoras',
        loadChildren: () =>
            import('./pages/lista-telefonica-emissoras/lista-telefonica-emissoras.module').then(
                (m) => m.ListaTelefonicaEmissorasModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.listaTelefonica.emissoras }
    },
    {
        path: 'lista-telefonica/contatos',
        loadChildren: () =>
            import('./pages/lista-telefonica-contatos/lista-telefonica-contatos.module').then(
                (m) => m.ListaTelefonicaContatosModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.listaTelefonica.contato }
    },
    {
        path: 'parametros/tipo-contato',
        loadChildren: () =>
            import('./pages/parametros/tipo-contato/tipo-contato.module').then(
                (m) => m.TipoContatoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.parametros.tipoContato }
    },
    {
        path: 'parametros/tipo-visualizacao',
        loadChildren: () =>
            import('./pages/parametros/tipo-visualizacao/tipo-visualizacao.module').then(
                (m) => m.TipoVisualizacaoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.parametros.tipoVisualizacao }
    },
    {
        path: 'parametros/salas',
        loadChildren: () =>
            import('./pages/parametros/salas/salas.module').then((m) => m.SalasModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.parametros.salas }
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            )
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
